<h2 mat-dialog-title>New Allergy</h2>
<mat-dialog-content>
  <form class="form" [formGroup]="form" *ngIf="form !== null" [class.form-validated]="validate">
    <mat-form-field appearance="fill" color="primary" class="w-100">
      <mat-label>Allergy</mat-label>
      <input matInput formControlName="allergy" type="text"/>
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end" class="mx-3">
  <button type="button" mat-button (click)="close()">Close</button>
  <button type="button" mat-flat-button color="primary" (click)="save()">Create</button>
</mat-dialog-actions>

