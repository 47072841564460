<div class="duplicate-patients p-3" *ngIf="hasDuplicates" [class.has-tabs]="hasTabs">
  <div class="mat-container">
      <div class=" d-flex flex-row align-items-center justify-content-between">
        <span>The following {{duplicates.length}} patient(s) match your current input.Please verify you are not adding a duplicate patient</span>
        <button mat-icon-button type="button" *ngIf="!duplicatesOpen" (click)="toggleIsOpen()">
          <mat-icon class="md-20">arrow_drop_down</mat-icon>
        </button>
        <button mat-icon-button type="button" *ngIf="duplicatesOpen" (click)="toggleIsOpen()">
          <mat-icon class="md-20">arrow_drop_up</mat-icon>
        </button>
      </div>
      <mat-list class="duplicates" [class.visible]="duplicatesOpen">
        <mat-list-item *ngFor="let duplicate of duplicates; let i = index">
          <div class="ms-2 me-auto d-flex flex-row align-items-center justify-content-between w-100">
            <div class="fw-bold">{{duplicate.profileId}} {{duplicate.displayName}} {{duplicate.identityNumber}}</div>
            <button mat-button (click)="editPatient(i)">Edit</button>
          </div>
        </mat-list-item>
      </mat-list>
  </div>
</div>
<mat-card appearance="outlined" class="mb-3" [class.valid]="formValid && validate" [class.invalid]="!formValid && validate">
  <a mat-icon-button [routerLink]="[backButtonRoute]"><mat-icon>close</mat-icon></a>
  <mat-card-content>
    <p *ngIf="validationEnabled && !formValid" class="card-text mat-text-warn" (click)="toggleFullErrorMessage()">Please correct any errors highlighted in red on the form.</p>
    <ul class="full-error-message" *ngIf="showFullErrorMessage">
      <li class="card-text mat-text-warn" *ngFor="let error of fullFormError">{{error.key}}: {{error.control.errors | json}}</li>
    </ul>
    <form class="form" [formGroup]="form" *ngIf="form" [class.form-validated]="validationEnabled">
    <input type="hidden" [name]="'profilePictureUrl'"/>
    <input type="hidden" [formControlName]="'status'" *ngIf="isEditForm">
    <div class="row mb-3">
      <div class="col-sm-12 col-md-6">
        <h2 class="mb-3">Name</h2>
        <mat-form-field appearance="fill" color="primary" class="w-100">
          <mat-label>Select Title</mat-label>
          <mat-select formControlName="title">
            <mat-option *ngFor="let title of titles" [value]="title.value">{{title.text}}</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" color="primary" class="w-100">
          <mat-label>First Name</mat-label>
          <input matInput formControlName="firstName" type="text"/>
        </mat-form-field>
        <mat-form-field appearance="fill" color="primary" class="w-100">
          <mat-label>Surname</mat-label>
          <input matInput formControlName="surname" type="text"/>
        </mat-form-field>
        <mat-form-field appearance="fill" color="primary" class="w-100">
          <mat-label>Middle Name(s)</mat-label>
          <input matInput formControlName="middleNames" type="text"/>
        </mat-form-field>
        <mat-form-field appearance="fill" color="primary" class="w-100">
          <mat-label>Nickname</mat-label>
          <input matInput formControlName="nickname" type="text"/>
        </mat-form-field>
      </div>
      <div class="col-sm-12 col-md-6">
        <app-allergies-widget />
      </div>
    </div>
    <mat-divider inset></mat-divider>
    <div class="row mb-3">
      <div class="col-12">
        <h2 class="mb-3">Identification</h2>
        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field appearance="fill" color="primary" class="w-100">
              <mat-label>Identity Number</mat-label>
              <input matInput formControlName="identityNumber" type="text"/>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-6">
            <mat-form-field appearance="fill" color="primary" class="w-100">
              <mat-label>Confirm Identity Number</mat-label>
              <input matInput formControlName="identityNumberConfirmation" type="text"/>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field appearance="fill" color="primary" class="w-100">
              <mat-label>Date Of Birth *</mat-label>
              <app-mat-date-time-input formControlName="dateOfBirth" [enableTime]="false" #dateTimeInput="dateTimeInput" [minDate]="minDobValue" [maxDate]="maxDobValue"></app-mat-date-time-input>
              <button mat-icon-button matSuffix (click)="dateTimeInput.togglePicker()"><mat-icon>today</mat-icon></button>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field appearance="fill" color="primary" class="w-100">
              <mat-label>Passport Number</mat-label>
              <input matInput formControlName="passportNumber" type="text"/>
            </mat-form-field>
          </div>
          <div class="col-12 col-md-6">
            <mat-form-field appearance="fill" color="primary" class="w-100">
              <mat-label>Confirm Passport Number</mat-label>
              <input matInput formControlName="passportNumberConfirmation" type="text"/>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <mat-divider inset></mat-divider>
    <div class="row mb-3">
      <div class="col-12">
        <h2 class="mb-3">Demographics</h2>
        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field appearance="fill" color="primary" class="w-100">
              <mat-label>Select Gender</mat-label>
              <mat-select formControlName="gender">
                <mat-option *ngFor="let option of gender" [value]="option.value">{{option.text}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field appearance="fill" color="primary" class="w-100">
              <mat-label>Select Language</mat-label>
              <mat-select formControlName="homeLanguage">
                <mat-option *ngFor="let option of languages" [value]="option.value">{{option.text}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field appearance="fill" color="primary" class="w-100">
              <mat-label>Select Race</mat-label>
              <mat-select formControlName="race">
                <mat-option *ngFor="let option of races" [value]="option.value">{{option.text}}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
    <mat-divider inset></mat-divider>
    <div class="row mb-3">
      <div class="col-12">
        <h2 class="mb-3">Contact Details</h2>
        <ng-container *ngFor="let contactNumberFormGroup of contactNumbers.controls; let i=index">
          <patient-contact-form [controlFormGroup]="contactNumberFormGroup" [index]="i" (delete)="removeContactDetail($event)"
                                [deleteEnabled]="contactNumbers.controls.length > 1"></patient-contact-form>
        </ng-container>
        <div class="row">
          <div class="col-12 col-md-6">
            <button mat-button color="primary" class="w-100" (click)="addContactDetail()">Add Phone Number</button>
          </div>
        </div>
      </div>
      <mat-divider inset class="w-50"></mat-divider>
      <div class="row">
        <div class="col-12">
          <h2 class="mb-3">Email Address Details</h2>
          <ng-container *ngFor="let emailAddressFormGroup of emailAddresses.controls; let i=index">
            <patient-email-form [controlFormGroup]="emailAddressFormGroup" [index]="i" (delete)="removeEmailAddress($event)"
                                [deleteEnabled]="emailAddresses.controls.length > 1"></patient-email-form>
          </ng-container>
          <div class="row">
            <div class="col-12 col-md-6">
              <button mat-button color="primary" class="w-100" (click)="addEmailAddress()">Add Email Address</button>
            </div>
          </div>
        </div>
      </div>
      <mat-divider inset class="w-50"></mat-divider>
      <div class="row">
        <div class="col-12">
          <h2 class="mb-3">Address Details</h2>
          <ng-container *ngFor="let addressFormGroup of addressDetails.controls; let i=index">
            <patient-address-form [controlFormGroup]="addressFormGroup" [index]="i" (delete)="removeAddress($event)"
                                  [deleteEnabled]="addressDetails.controls.length > 1"></patient-address-form>
          </ng-container>
          <div class="row">
            <div class="col-12 col-md-6">
              <button mat-button color="primary" class="w-100" (click)="addAddress()">Add Address</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p class="text-center w-50" [class.mat-text-warn]="validationEnabled && !formValid">At least one contact method is required</p>
    <mat-divider inset></mat-divider>
    <div class="row mb-3">
      <div class="col-sm-12">
        <h2>Secondary Contact Details</h2>
        <ng-container *ngFor="let secondaryContactFormGroup of secondaryContacts.controls; let i=index">
          <app-secondary-contact-form [index]="i" [controlForm]="secondaryContactFormGroup" [validate]="validate" (delete)="removeSecondaryContactDetail(i)"
                                      [deleteEnabled]="secondaryContacts.controls.length > 1"></app-secondary-contact-form>
        </ng-container>
        <div class="row">
          <div class="col-12 col-md-6">
            <button mat-button color="primary" class="w-100" (click)="addSecondaryContactDetail()">Add Secondary Contact</button>
          </div>
        </div>
      </div>
    </div>
    <mat-divider inset></mat-divider>
    <div class="row mb-3">
      <div class="col-sm-12 col-md-6">
        <h2>Family Doctor's Details</h2>
        <app-mat-autocomplete placeholder="Search Doctors" [searchObservable]="searchDoctors"
                              [addItemCardText]="'Click here to add a new doctor.'" #appMatAutocompleteComponent
                              [displayResultsCardTemplate]='doctorsTemplate' (addNewItem)="addNewDoctor()" [alwaysShowAddCard]="true"
                              (itemSelected)="familyDoctorSelected($event)"></app-mat-autocomplete>
        <ng-template #doctorsTemplate let-result="result" let-queryText="queryText">
          <div class="col-12">
            <div class="row">
              <div class="col-6"><span class="py-1 px-3 mb-0 fw-bold" [innerHtml]="result.name | textHighlight: queryText"></span></div>
              <div class="col-3"><span class="py-1 px-3 mb-0" [innerHtml]="result.practiceNumber | textHighlight: queryText"></span></div>
              <div class="col-3"><span class="py-1 px-3 mb-0" [innerHtml]="result.tel | textHighlight: queryText"></span></div>
            </div>
          </div>
        </ng-template>
      </div>
      <div class="col-sm-12 col-md-6">
        <h2>Referring Doctor's Details</h2>
        <app-mat-autocomplete placeholder="Search Doctors" [searchObservable]="searchDoctors"
                              [addItemCardText]="'Click here to add a new doctor.'" #appMatAutocompleteComponent
                              [displayResultsCardTemplate]='doctorsTemplate' (addNewItem)="addNewDoctor()" [alwaysShowAddCard]="true"
                              (itemSelected)="referringDoctorSelected($event)"></app-mat-autocomplete>
        <ng-template #doctorsTemplate let-result="result" let-queryText="queryText">
          <div class="col-12">
            <div class="row">
              <div class="col-6"><span class="py-1 px-3 mb-0 fw-bold" [innerHtml]="result.name | textHighlight: queryText"></span></div>
              <div class="col-3"><span class="py-1 px-3 mb-0" [innerHtml]="result.practiceNumber | textHighlight: queryText"></span></div>
              <div class="col-3"><span class="py-1 px-3 mb-0" [innerHtml]="result.tel | textHighlight: queryText"></span></div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6">
        <ng-container *ngFor="let doctor of familyDoctors.controls let i=index">
          <app-doctor-display-card [controlFormGroup]="doctor" [index]="i" (delete)="removeFamilyDoctor($event)"></app-doctor-display-card>
        </ng-container>
        <div class="px-3 pb-3 w-100 text-center" *ngIf="familyDoctors.controls.length===0">
          <span>No family doctors have been added to this patient.</span>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <ng-container *ngFor="let doctor of referringDoctors.controls let i=index">
          <app-doctor-display-card [controlFormGroup]="doctor" [index]="i" (delete)="removeReferringDoctor($event)"></app-doctor-display-card>
        </ng-container>
        <div class="px-3 pb-3 w-100 text-center" *ngIf="referringDoctors.controls.length===0">
          <span>No referring doctors have been added to this patient.</span>
        </div>
      </div>
    </div>
    <mat-divider inset></mat-divider>
    <div class="row mb-3" [formGroup]="billingContact">
        <div class="col-12">
          <div class="d-flex justify-content-between align-items-center mb-3">
            <h2 class="mb-0">Person Responsible For Payment</h2>
            <button mat-flat-button color="primary" (click)="setBillingDetailsToPatientDetails()">Same As Patient</button>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <mat-form-field appearance="fill" color="primary" class="w-100">
                <mat-label>First Name</mat-label>
                <input matInput formControlName="billingContactFirstName" type="text"/>
              </mat-form-field>
              <mat-form-field appearance="fill" color="primary" class="w-100">
                <mat-label>Surname</mat-label>
                <input matInput formControlName="billingContactSurname" type="text"/>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6">
              <mat-form-field appearance="fill" color="primary" class="w-100">
                <mat-label>Identity Number</mat-label>
                <input matInput formControlName="billingContactIdentityNumber" type="text"/>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6">
              <mat-form-field appearance="fill" color="primary" class="w-100">
                <mat-label>Confirm Identity Number</mat-label>
                <input matInput formControlName="billingContactIdentityNumberConfirmation" type="text"/>
              </mat-form-field>
            </div>
          </div>
        </div>
      <div class="row">
        <div class="col-12">
          <h4 class="mb-3">Contact Details</h4>
          <ng-container *ngFor="let contactNumberFormGroup of billingContactContactNumbers.controls; let i=index">
            <patient-contact-form [controlFormGroup]="contactNumberFormGroup" [index]="i" (delete)="removeBillingContactContactDetail($event)"
                                  [deleteEnabled]="billingContactContactNumbers.controls.length > 1"></patient-contact-form>
          </ng-container>
          <div class="row">
            <div class="col-12 col-md-6">
              <button mat-button color="primary" class="w-100" (click)="addBillingContactContactDetail()">Add Phone Number</button>
            </div>
          </div>
        </div>
        <mat-divider inset class="w-50"></mat-divider>
        <div class="row">
          <div class="col-12">
            <h4 class="mb-3">Email Address Details</h4>
            <ng-container *ngFor="let emailAddressFormGroup of billingContactEmailAddresses.controls; let i=index">
              <patient-email-form [controlFormGroup]="emailAddressFormGroup" [index]="i" (delete)="removeBillingContactEmailAddress($event)"
                                  [deleteEnabled]="billingContactEmailAddresses.controls.length > 1"></patient-email-form>
            </ng-container>
            <div class="row">
              <div class="col-12 col-md-6">
                <button mat-button color="primary" class="w-100" (click)="addBillingContactEmailAddress()">Add Email Address</button>
              </div>
            </div>
          </div>
        </div>
        <mat-divider inset class="w-50"></mat-divider>
        <div class="row">
          <div class="col-12">
            <h4 class="mb-3">Address Details</h4>
            <ng-container *ngFor="let addressFormGroup of billingContactAddressDetails.controls; let i=index">
              <patient-address-form [controlFormGroup]="addressFormGroup" [index]="i" (delete)="removeBillingContactAddress($event)"
                                    [deleteEnabled]="billingContactAddressDetails.controls.length > 1"></patient-address-form>
            </ng-container>
            <div class="row">
              <div class="col-12 col-md-6">
                <button mat-button color="primary" class="w-100" (click)="addBillingContactAddress()">Add Address</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p class="text-center w-50" [class.mat-text-warn]="validationEnabled && !formValid">At least one billing contact method is required</p>
    <mat-divider inset></mat-divider>
    <div class="mb-3" [formGroup]="medicalAid">
      <h2>Medical Aid Details</h2>
      <input type="hidden" [name]="'destinationCode'"/>
      <input type="hidden" [name]="'scheme'"/>
      <div class="row" *ngIf="!hasScheme">
        <div class="col-sm-12 col-md-6">
          <app-mat-autocomplete placeholder="Search Schemes" (itemSelected)="setMedicalScheme($event)" [searchObservable]="searchSchemes"
                                [displayResultsCardTemplate]='schemeTemplate'></app-mat-autocomplete>
          <ng-template #schemeTemplate let-result="result" let-queryText="queryText">
            <div class="p-2">
              <h2 class="mb-1" [innerHtml]="'Name: ' + result.name | textHighlight: queryText"></h2>
              <p class="mb-1">Administrator: {{result.administrator}}</p>
            </div>
          </ng-template>
        </div>
      </div>
      <div class="row" *ngIf="hasScheme">
        <div class="col-sm-12 col-md-6">
          <mat-form-field appearance="fill" color="primary" class="w-100">
            <mat-label>Medical Aid</mat-label>
            <input matInput formControlName="scheme" type="text" readonly/>
            <button matSuffix mat-icon-button (click)="clearMedicalScheme()">
              <mat-icon>close</mat-icon>
            </button>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <mat-form-field appearance="fill" color="primary" class="w-100">
            <mat-label>Medical Aid Number</mat-label>
            <input matInput formControlName="medicalAidNumber" type="text"/>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <mat-form-field appearance="fill" color="primary" class="w-100">
            <mat-label>Dependant Code</mat-label>
            <input matInput formControlName="dependantCode" type="text"/>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <mat-form-field appearance="fill" color="primary" class="w-100">
            <mat-label>Main Member&apos;s First Name</mat-label>
            <input matInput formControlName="mainMembersFirstName" type="text"/>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <mat-form-field appearance="fill" color="primary" class="w-100">
            <mat-label>Main Member&apos;s Surname</mat-label>
            <input matInput formControlName="mainMembersSurname" type="text"/>
          </mat-form-field>
        </div>
      </div>
    </div>
    <mat-divider inset></mat-divider>
    <div class="row">
      <div class="col-12 col-md-6">
        <h2>Bookkeeping</h2>
        <mat-form-field appearance="fill" color="primary" class="w-100">
          <mat-label>Folio Number</mat-label>
          <input matInput formControlName="fileNumber" type="text"/>
        </mat-form-field>
      </div>
    </div>
    <p *ngIf="validationEnabled && !formValid" class="card-text mat-text-warn" (click)="toggleFullErrorMessage()">Please correct any errors highlighted in red on the form.</p>
    <ul class="full-error-message" *ngIf="showFullErrorMessage">
      <li class="card-text mat-text-warn" *ngFor="let error of fullFormError">{{error.key}}: {{error.control.errors | json}}</li>
    </ul>
  </form>
  </mat-card-content>
  <mat-card-actions>
    <a mat-flat-button [routerLink]="[backButtonRoute]">Cancel</a>
    <button mat-flat-button color="primary" (confirm)="save()" appConfirmationDialog [confirmMessage]="'Are you sure you wish to add this patient to the system?'"
            *appPermissionRequired="'Clinical.Patients.Write'; condition: !isEditForm">Create Patient
    </button>
    <button mat-flat-button color="primary" (confirm)="save()" appConfirmationDialog [confirmMessage]="'Are you sure you wish to update this patient?'"
            *appPermissionRequired="'Clinical.Patients.Write'; condition: isEditForm">Save Changes
    </button>
  </mat-card-actions>
</mat-card>
