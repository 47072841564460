import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Appointment} from '../../patient-appointments-repository/entities/appointment';
import {CoreService} from '../../../core/services/core.service';
import {AppointmentType} from '../../patient-appointments-repository/entities/appointment-type';
import {PatientAppointmentsRepositoryService} from '../../patient-appointments-repository/patient-appointments-repository.service';
import {Subscription} from 'rxjs';
import {PatientService} from '../../patients/services/patient.service';
import {filter} from 'rxjs/operators';
import {WebsocketService} from '../../../core/services/websocket.service';
import {CoreEventsService} from '../../../core/services/core-events.service';
import {PatientEventsService} from '../../patients/services/patient-events.service';

@Component({
    selector: 'patient-appointments-widget',
    templateUrl: './appointments-widget.component.html',
    styleUrls: ['./appointments-widget.component.scss']
})
export class AppointmentsWidgetComponent implements OnInit, OnDestroy {


  error = '';
  loading = true;
  saving = false;
  appointments: Appointment[] = [];
  appointmentTypes: AppointmentType[] = [];
  form: UntypedFormGroup = null;

  private subscriptions: Subscription[] = [];
  private channelName = '';
  private readonly eventName = 'appointment_changed';

  constructor(
    private formBuilder: UntypedFormBuilder,
    private coreService: CoreService,
    private coreEventService: CoreEventsService,
    private patientService: PatientService,
    private patientEventService: PatientEventsService,
    private pushService: WebsocketService,
    private appointmentsRepository: PatientAppointmentsRepositoryService
  ) { }

  ngOnInit(): void {
    this.channelName = this.patientService.currentPatientEventsChannelName;
    this.form = this.formBuilder.group({
      appointmentDate: [null, Validators.required], // quirk to fix invalid date error on form. if value is null, date is valid, if value is empty string, its not a valid DATE so the field is marked invalid
      bookingComment: ['']
    });
    this.subscriptions.push(this.patientService.patientAppointments$.subscribe(appointments => {
      this.loading = false;
      const sortedAppointments = appointments.sort((a, b) => new Date(b.start).getTime() -  new Date(a.start).getTime());
      this.appointments = [...sortedAppointments];
    }));
    this.subscriptions.push(this.patientEventService.currentPatient$.subscribe(() => {
      this.loadAppointments();
    }));
    this.subscriptions.push(this.patientEventService.error$.pipe(filter(error => error.type === 'appointments')).subscribe(error => this.error = error.message));
    this.subscriptions.push(this.coreEventService.events$.pipe(filter(event => event.type === 'appointment_types_loaded')).subscribe(_ =>
      this.appointmentTypes = this.coreService.appointmentTypes.filter(t => t.isPatientAppointment).sort((a, b) => a.id > b.id ? 1 : -1)));
    this.appointmentTypes = this.coreService.appointmentTypes?.filter(t => t.isPatientAppointment).sort((a, b) => a.id > b.id ? 1 : -1) ?? [];
    this.pushService.subscribe(this.channelName, this.eventName, _ => {
      this.loadAppointments();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.pushService.unsubscribe(this.channelName, this.eventName);
  }

  get hasError(): boolean {
      return this.error.length > 0;
  }

  get hasAppointments(): boolean {
      return this.appointments.length > 0 && this.appointmentTypes.length > 0;
  }

  loadAppointments() {
    if (!this.patientService.hasPatientDetails) { return; }
    this.error = '';
    this.patientService.loadPatientAppointments();
  }

  saveAppointment() {
    this.saving = true;
    const field = this.form.get('appointmentDate') as UntypedFormControl;
    const date = field.value;
    const appointment = {
      title: this.patientService.currentPatient.formalName,
      start: new Date(date),
      comment: '',
      practiceId: this.coreService.selectedPractice.id,
      createdDate: new Date(),
      lastModifiedDate: new Date(),
      createdUser: this.coreService.currentUser,
      lastModifiedUser: this.coreService.currentUser,
      deleted: false,
      appointmentType: this.appointmentTypes[0]
    } as Appointment;
    const tempAppointments = [...this.appointments];
    tempAppointments.push(appointment);
    tempAppointments.sort((a, b) => new Date(b.start).getTime() - new Date(a.start).getTime());
    this.appointments = [...tempAppointments];
    this.error = '';
    this.subscriptions.push(this.appointmentsRepository.addAppointmentToPatient(this.patientService.currentPatient.id, appointment).subscribe(id => {
      field.setValue(null);
      this.saving = false;
    }, error => {
      this.error = 'Could not save your new appointment';
      field.setValue(null);
      this.saving = false;
    }));
  }

}
