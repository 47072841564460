import {AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {QueryPatient} from '../../patient-repository/entities/patient';
import {AppTabsService} from '../../../core/services/tabs.service';
import {AppointmentType} from '../../patient-appointments-repository/entities/appointment-type';
import {Appointment} from '../../patient-appointments-repository/entities/appointment';
import {MatTableDataSource} from '@angular/material/table';
import {debounceTime} from 'rxjs/operators';
import {Subject, Subscription} from 'rxjs';
import {PatientAppointmentsRepositoryService} from '../../patient-appointments-repository/patient-appointments-repository.service';
import {MatTable} from '@angular/material/table';
import {CoreService} from '../../../core/services/core.service';

@Component({
  selector: 'app-todays-patient-details-table',
  templateUrl: './todays-patient-details-table.component.html',
  styleUrls: ['./todays-patient-details-table.component.scss']
})
export class TodaysPatientDetailsTableComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @Input() appointments: Appointment[] = [];
  @Input() types: AppointmentType[] = [];

  private sortedAppointments: Appointment[];
  dataSource = new MatTableDataSource<Appointment>([]);
  displayedColumns: string[] = ['status', 'time', 'profileId', 'name', 'cell', 'identityNumber', 'medicalAidNumber', 'reason', 'type', 'actions'];

  commentTextChanged = new Subject<Appointment>();
  private commentChangedSubscription: Subscription;
  private appointmentRepositorySubscription: Subscription;
  private debounceTime = 750;

  @ViewChild('todaysPatientsTable') matTable: MatTable<Appointment>;
  constructor(private coreService: CoreService, private tabService: AppTabsService, private appointmentsRepository: PatientAppointmentsRepositoryService) { }

  ngOnInit(): void {
    this.commentChangedSubscription = this.commentTextChanged.pipe(debounceTime(this.debounceTime)).subscribe(appointment => this.updateAppointment(appointment));
    this.sortedAppointments = this.appointments.sort(this.appointmentSorter);
  }

  ngOnDestroy(): void {
    this.commentChangedSubscription?.unsubscribe();
    this.appointmentRepositorySubscription?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.appointments) {
      this.sortedAppointments = this.appointments.sort(this.appointmentSorter);
      this.dataSource.data = [...this.sortedAppointments];
    }
    this.matTable?.renderRows();
  }

  ngAfterViewInit() {
    this.dataSource = new MatTableDataSource<Appointment>(this.sortedAppointments);
  }

  view(patient: QueryPatient): void {
    this.tabService.openTabWithSearchPatient(patient);
  }

  updateAppointmentStatus(appointment: Appointment, newTypeId: number) {
    appointment.appointmentType = this.coreService.appointmentTypes.find(type => type.id === newTypeId);
    this.updateAppointment(appointment);
  }

  private updateAppointment(appointment: Appointment): void {
    const appointmentIndex = this.sortedAppointments.indexOf(appointment);
    this.sortedAppointments[appointmentIndex] = appointment;
    this.dataSource = new MatTableDataSource(this.sortedAppointments);
    this.appointmentRepositorySubscription = this.appointmentsRepository.updateAppointment(appointment).subscribe(() => {
    }, error => {
      console.error(error);
    });
  }

  private appointmentSorter(a: Appointment, b: Appointment): number {
    if (a.start === b.start) {
      return a.title.localeCompare(b.title);
    }
    return a.start > b.start ? 1 : -1;
  }
}
