<mat-card appearance="outlined">
  <mat-card-content>
    <table mat-table [dataSource]="dataSource" class="w-100" #todaysPatientsTable>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" [style.background-color]="element.patient?.status?.color"></td>
      </ng-container>
      <ng-container matColumnDef="time">
        <th mat-header-cell *matHeaderCellDef>Time</th>
        <td mat-cell *matCellDef="let element">{{element.start | date:'HH:mm':'+0200'}}</td>
      </ng-container>
      <ng-container matColumnDef="profileId">
        <th mat-header-cell *matHeaderCellDef>File No</th>
        <td mat-cell *matCellDef="let element">{{element.patient?.profileId}}</td>
      </ng-container>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name. </th>
        <td mat-cell *matCellDef="let element">{{element.title ?? element.patient?.formalName}}</td>
      </ng-container>
      <ng-container matColumnDef="cell">
        <th mat-header-cell *matHeaderCellDef class="d-responsive d-md"> Cell No </th>
        <td mat-cell *matCellDef="let element" class="d-responsive d-md">{{element.patient?.cell ?? element.appointmentMetaData?.cell ?? ''}}</td>
      </ng-container>
      <ng-container matColumnDef="identityNumber">
        <th mat-header-cell *matHeaderCellDef class="d-responsive d-lg"> ID No </th>
        <td mat-cell *matCellDef="let element" class="d-responsive d-lg">{{element.patient?.identityNumber}}</td>
      </ng-container>
      <ng-container matColumnDef="medicalAidNumber">
        <th mat-header-cell *matHeaderCellDef class="d-responsive d-lg"> Medical Aid No </th>
        <td mat-cell *matCellDef="let element" class="d-responsive d-lg">{{element.patient?.medicalAidNumber}}</td>
      </ng-container>
      <ng-container matColumnDef="reason">
        <th mat-header-cell *matHeaderCellDef class="d-responsive d-xl"> Reason </th>
        <td mat-cell *matCellDef="let element" class="d-responsive d-xl">
          <mat-form-field appearance="fill" *appPermissionRequired="'Clinical.Appointments.Write'; else: readonlyComment" subscriptSizing="dynamic" class="w-100 compact">
            <mat-label>Reason</mat-label>
            <input matInput type="text" [(ngModel)]="element.comment"  (ngModelChange)='commentTextChanged.next(element)'>
          </mat-form-field>
          <ng-template  #readonlyComment>
            <mat-form-field appearance="fill" subscriptSizing="dynamic" class="w-100">
              <mat-label></mat-label>
              <input readonly matInput type="text" [ngModel] = "element.comment">
            </mat-form-field>
          </ng-template>
        </td>
      </ng-container>
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef class="d-responsive d-md"> Status </th>
        <td mat-cell *matCellDef="let element" class="d-responsive d-md">
          <div class="appointment-status-holder" [style.background-color]="element.appointmentType.displayStyle">{{element.appointmentType.title}}</div>
        </td>
      </ng-container>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef> Actions </th>
        <td mat-cell *matCellDef="let element" style="text-align: center">
          <button mat-icon-button *appPermissionRequired="'Clinical.Patients.Read'; condition: element.appointmentType.isPatientAppointment && element.patient != null" (click)="view(element.patient)">
            <mat-icon>open_in_new</mat-icon>
          </button>
          <button mat-icon-button *appPermissionRequired="'Clinical.Patients.Write'; condition: element.appointmentType.isPatientAppointment && element.patient == null" [routerLink]="['/patients/new']">
            <mat-icon>library_add</mat-icon>
          </button>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </mat-card-content>
</mat-card>
