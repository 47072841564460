import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Allergy} from '../../../allergies-repository/entities/allergy';
import {Subscription} from 'rxjs';
import {MatDialogRef} from '@angular/material/dialog';
import {AllergiesRepositoryService} from '../../../allergies-repository/allergies-repository.service';

@Component({
  selector: 'app-allergy-add-card',
  templateUrl: './allergy-add-card.component.html',
  styleUrl: './allergy-add-card.component.scss'
})
export class AllergyAddCardComponent implements OnInit, OnDestroy{
  constructor(private dialogRef: MatDialogRef<AllergyAddCardComponent>, private formBuilder: UntypedFormBuilder, private allergyRepository: AllergiesRepositoryService) { }
  private repositorySubscription: Subscription;
  validate = false;
  form: UntypedFormGroup;
  @Output() allergySaved: EventEmitter<Allergy> = new EventEmitter();


  ngOnInit(): void {
    this.form = this.formBuilder.group({
      allergy: ['', Validators.required],
    });
  }

  ngOnDestroy(): void {
    if (this.repositorySubscription) { this.repositorySubscription.unsubscribe(); }
  }


  save() {
    if (this.form.valid) {
      const allergy = this.formToAllergy();
      this.repositorySubscription = this.allergyRepository.createAllergyItem(allergy).subscribe(result => {
        this.dialogRef.close(allergy);
      });
    }
    this.validate = true;
  }

  close() {
    this.dialogRef.close();
  }

  private formToAllergy(): Allergy {
    return this.form.value as Allergy;
  }
}
