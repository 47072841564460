import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {CoreService} from '../../../../core/services/core.service';
import {PatientService} from '../../../patients/services/patient.service';
import {PatientEvent, PatientEventsService} from '../../../patients/services/patient-events.service';
import {TextSanitizerPipe} from '../../../../shared/pipes/text-sanitizer.pipe';
import {Patient} from '../../../patient-repository/entities/patient';
import {Allergy} from '../../../allergies-repository/entities/allergy';
import {AllergiesRepositoryService} from '../../../allergies-repository/allergies-repository.service';

@Component({
  selector: 'app-allergy-item',
  templateUrl: './allergy-item.component.html',
  styleUrl: './allergy-item.component.scss'
})
export class AllergyItemComponent implements OnInit, OnDestroy{
  private repositorySubscription: Subscription;
  error = '';
  loading = false;
  @Input() item: Allergy;
  @Input() usePrimaryText = false;

  constructor(
    private coreService: CoreService,
    private patientService: PatientService,
    private patientEventService: PatientEventsService,
    private allergyRepository: AllergiesRepositoryService,
    private textSanitizePipe: TextSanitizerPipe
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    if (this.repositorySubscription) { this.repositorySubscription.unsubscribe(); }
  }



  get patientId(): number {
    return this.patientService.currentPatient.id;
  }

  get patient(): Patient {
    return this.patientService.currentPatient;
  }

  get confirmationMessage(): string {
    return 'Are you sure you want to remove ' +
      this.textSanitizePipe.transform(this.item.allergy)  +
      ' from ' + this.patient.firstName + ' ' + this.patient.surname + '?';
  }

  public removeAllergyFromPatient(allergyId: number) {
    this.loading = true;
    this.error = '';
    this.repositorySubscription = this.allergyRepository.removeAllergyFromPatient(this.patientId, allergyId).subscribe(() => {
      this.loading = false;
      this.patientEventService.emitEvent(new PatientEvent('allergy_item_deleted'));
    }, error => {
      this.error = 'Could not remove this allergy item from this patient';
      this.coreService.showErrorToast(this.error);
      this.loading = false;
    });
  }
}
