<h1>Allergies</h1>

<app-mat-autocomplete placeholder="Search Allergies"
                      [alwaysShowAddCard]="true" [searchObservable]="searchAllergies$"
                      (addNewItem)='onAddNewItem()' [addItemCardText]="'Add new allergy.'"
                      [displayResultsCardTemplate]='displayResultsCardTemplate'
                      *appPermissionRequired="'Clinical.AllergyListing.Write'; condition: !readonly"></app-mat-autocomplete>
<ng-template #displayResultsCardTemplate let-result="result" let-queryText="queryText">
  <div class="d-flex flex-row justify-content-between align-items-center">
    <span [innerHtml]="result.allergy | textHighlight: queryText"></span>
    <button mat-icon-button (click)="onItemSelected(result)"><mat-icon>add</mat-icon></button>
  </div>
  <mat-form-field color="primary" appearance="fill" class="compact w-100" subscriptSizing="dynamic">
    <mat-label>Comments/Notes</mat-label>
    <input matInput type="text" [(ngModel)]="result.comment"/>
  </mat-form-field>
</ng-template>

<app-loading-spinner *ngIf="loading"></app-loading-spinner>

<div class="mat-card-list" *appPermissionRequired="'Clinical.AllergyListing.Read'; condition: !readonly">
  <ng-container  *ngFor="let item of allergies;">
    <mat-card class="compact">
      <app-allergy-item [item]="item" [usePrimaryText]="settings.themeWidgetText" class="w-100"></app-allergy-item>
    </mat-card>
  </ng-container>

  <mat-card *ngIf="!hasAllergies">
    <mat-card-content>
      <span>No allergies have been added to this patient.</span>
    </mat-card-content>
  </mat-card>
</div>

<mat-list *ngIf="readonly">
  <mat-list-item class="compact" *ngFor="let item of allergies;">
    <mat-icon class="ms-0 me-3" matListItemIcon>warning</mat-icon>
    <div matListItemTitle>{{item.allergy}}</div>
  </mat-list-item>
  <mat-list-item *ngIf="!hasAllergies">
    <mat-icon matListItemIcon>info</mat-icon>
    <div matListItemTitle>No allergies have been added to this patient.</div>
  </mat-list-item>
</mat-list>
