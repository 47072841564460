import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Patient} from '../../patient-repository/entities/patient';
import {CoreEventsService} from '../../../core/services/core-events.service';

@Injectable({
  providedIn: 'root'
})
export class PatientEventsService {
  private eventSubject: Subject<PatientEvent> = new Subject();
  events$: Observable<PatientEvent> = this.eventSubject.asObservable();
  private currentPatientSubject: BehaviorSubject<Patient> = new BehaviorSubject(null);
  currentPatient$: Observable<Patient> = this.currentPatientSubject.asObservable();
  private errorSubject: BehaviorSubject<ErrorEvent> = new BehaviorSubject(new ErrorEvent('clear', ''));
  error$: Observable<ErrorEvent> = this.errorSubject.asObservable();
  private currentPatientId: number = null;

  constructor(private coreEventService: CoreEventsService) { }

  get currentPatientEventsChannelName() {
    return `xmedical_practice_${this.coreEventService.selectedPracticeId}_patients_${this.currentPatientId}_events`;
  }

  emitEvent(event: PatientEvent) {
    this.eventSubject.next(event);
  }

  emitError(error: ErrorEvent) {
    this.errorSubject.next(error);
  }

  setCurrentPatient(patient: Patient) {
    this.currentPatientId = patient ? patient.id : null;
    this.currentPatientSubject.next(patient);
  }
}

export type PatientEventType =
  | 'initialized'
  | 'scripts_updated'
  | 'treatment_plans_updated'
  | 'treatment_plan_action_item_deleted'
  | 'treatment_plan_action_item_added'
  | 'medication_item_deleted'
  | 'medication_item_added'
  | 'problem_item_deleted'
  | 'problem_item_added'
  | 'allergy_item_deleted'
  | 'allergy_item_added'
  | 'appointments_updated'
  | 'comments_updated'
  | 'emails_updated'
  | 'sms_updated'
  | 'draft_added'
  | 'compliance_updated'
  | 'reports_updated'
  | 'child_route_loaded'
  | 'patient_details_loaded'
  | 'patient_created'
  | 'patient_updated'
  | 'patient_status_update_requested'
  | 'patient_status_updated'
  | 'trigger_save';

export type ErrorEventType =
  | 'clear'
  | 'error'
  | 'patient'
  | 'emails'
  | 'messages'
  | 'storage'
  | 'drafts'
  | 'draft_count'
  | 'reports'
  | 'imported_reports'
  | 'problems'
  | 'medication'
  | 'appointments'
  | 'treatment_plan_items'
  | 'treatment_plans'
  | 'scripts'
  | 'uploads'
  | 'procedure_fields'
  | 'procedure_entries';

export class PatientEvent {
  constructor(readonly type: PatientEventType, readonly data?: any) {
  }
}

export class ErrorEvent {
  constructor(readonly type: ErrorEventType, readonly message: string) {
  }
}
