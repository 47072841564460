import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ItemCreatedResponse} from '../base/entities/item-created-response';
import {Allergy} from './entities/allergy';
import {ApiService} from '../base/repository/api.service';
import {AdminApiService} from '../base/repository/admin-api.service';
import {GenericPaginationResponse} from '../base/entities/generic-pagination-response';
import {ItemDeletedResponse} from '../base/entities/item-deleted-response';
import {ItemUpdatedResponse} from '../base/entities/item-updated-response';
import {AllergyPaginationResponse} from './entities/allergy-pagination-response';
import {AllergyCollectionResponse} from './entities/allergy-collection-response';

@Injectable({
  providedIn: 'root'
})
export class AllergiesRepositoryService {

  constructor(private api: ApiService, private adminApi: AdminApiService) {
  }

  query(query: string): Observable<Allergy[]> {
    return this.api.get<AllergyCollectionResponse>('/allergylist/search/', {query: query})
      .pipe(map(response => response.items));
  }

  loadPatientAllergyList(patientId: number): Observable<Allergy[]> {
    return this.api.get<AllergyCollectionResponse>('/allergylist/patient/' + patientId)
      .pipe(map(response => response.items));
  }

  addAllergyToPatient(patientId: number, allergy: Allergy) {
    return this.api.post<ItemCreatedResponse>('/allergylist/patient/' + patientId, allergy)
      .pipe(map(response => response));
  }

  removeAllergyFromPatient(patientId: number, allergyId: number) {
    return this.api.delete<ItemDeletedResponse>('/allergylist/patient/' + patientId + '/' + allergyId)
      .pipe(map(response => response));
  }

  createAllergyItem(allergy: Allergy): Observable<number> {
    return this.api.post<ItemCreatedResponse>('/allergylist', allergy)
      .pipe(map(response => response.value));
  }

  adminPaginate(type: string, pageNumber: number, pageSize: number, filter: string): Observable<AllergyPaginationResponse> {
    return this.adminApi.get<GenericPaginationResponse<AllergyPaginationResponse>>('/allergylist/' + type, {pageNumber: pageNumber, pageSize: pageSize, filter: filter})
      .pipe(map(response => response.value));
  }

  adminCreateAllergy(allergy: Allergy): Observable<number> {
    return this.adminApi.post<ItemCreatedResponse>('/allergylist', allergy)
      .pipe(map(response => response.value));
  }

  adminUpdateAllergy(id: number, allergy: Allergy): Observable<boolean> {
    return this.adminApi.put<ItemUpdatedResponse>('/allergylist/' + id, allergy)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

  adminDeleteAllergy(id: number): Observable<boolean> {
    return this.adminApi.delete<ItemDeletedResponse>('/allergylist/' + id)
      .pipe(map(response => response.status.toLocaleUpperCase() === 'OK'));
  }

}
