import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AddressFormComponent} from './patient-form/address-form/address-form.component';
import {AppointmentsWidgetComponent} from './appointments-widget/appointments-widget.component';
import {CommentsWidgetComponent} from './comments-widget/comments-widget.component';
import {ContactFormComponent} from './patient-form/contact-form/contact-form.component';
import {DoctorAddCardComponent} from './doctor-add-card/doctor-add-card.component';
import {DoctorDisplayCardComponent} from './patient-form/doctor-display-card/doctor-display-card.component';
import {EmailFormComponent} from './patient-form/email-form/email-form.component';
import {MedicationWidgetComponent} from './medication-widget/medication-widget.component';
import {PatientFormComponent} from './patient-form/patient-form.component';
import {ProblemWidgetComponent} from './problem-widget/problem-widget.component';
import {ScriptsWidgetComponent} from './scripts-widget/scripts-widget.component';
import {TreatmentPlanActionsWidgetComponent} from './treatment-plan-actions-widget/treatment-plan-actions-widget.component';
import {TreatmentPlansWidgetComponent} from './treatment-plans-widget/treatment-plans-widget.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SharedModule} from 'src/app/shared/shared.module';
import {RouterModule} from '@angular/router';
import {CreateEmailComponent} from './create-email/create-email.component';
import {CreateMessageComponent} from './create-message/create-message.component';
import {CreateCommentComponent} from './create-comment/create-comment.component';
import {FileUploadsModule} from '../file-uploads/file-uploads.module';
import {TextSanitizerPipe} from '../../shared/pipes/text-sanitizer.pipe';
import {AppointmentWidgetComponent} from './appointments-widget/appointment-widget/appointment-widget.component';
import {CreateReportComponent} from './create-report/create-report.component';
import {ReportRendererModule} from '../report-renderer/report-renderer.module';
import {TodaysPatientDetailsTableComponent} from './todays-patient-details-table/todays-patient-details-table.component';
import {FlaggedPatientsTableComponent} from './flagged-patients-table/flagged-patients-table.component';
import {QuickNoteComponent} from './quick-note/quick-note.component';
import {ScriptWidgetComponent} from './scripts-widget/script-widget/script-widget/script-widget.component';
import {TreatmentPlanWidgetComponent} from './treatment-plans-widget/treatment-plan-widget/treatment-plan-widget/treatment-plan-widget.component';
import {TreatmentPlanActionItemComponent} from './treatment-plan-actions-widget/treatment-plan-action-item/treatment-plan-action-item.component';
import {ProblemItemComponent} from './problem-widget/problem-item/problem-item.component';
import {MedicationItemComponent} from './medication-widget/medication-item/medication-item.component';
import {CreateScriptComponent} from './create-script/create-script.component';
import {MedicationItemAddCardComponent} from './medication-item-add-card/medication-item-add-card.component';
import {ProblemItemAddCardComponent} from './problem-item-add-card/problem-item-add-card.component';
import {TreatmentPlanItemAddCardComponent} from './treatment-plan-item-add-card/treatment-plan-item-add-card.component';
import {SecondaryContactFormComponent} from './patient-form/secondary-contact-form/secondary-contact-form.component';
import {TranscribeModule} from '../transcribe/transcribe.module';
import {MatMenuModule} from '@angular/material/menu';
import {MatCardModule} from '@angular/material/card';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTableModule} from '@angular/material/table';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatTooltipModule} from '@angular/material/tooltip';
import { AllergiesWidgetComponent } from './allergies-widget/allergies-widget.component';
import { AllergyItemComponent } from './allergies-widget/allergy-item/allergy-item.component';
import { AllergyAddCardComponent } from './allergies-widget/allergy-add-card/allergy-add-card.component';


@NgModule({
  declarations: [
    AddressFormComponent,
    ContactFormComponent,
    SecondaryContactFormComponent,
    DoctorDisplayCardComponent,
    DoctorAddCardComponent,
    EmailFormComponent,
    PatientFormComponent,
    MedicationWidgetComponent,
    ProblemWidgetComponent,
    AppointmentsWidgetComponent,
    ScriptsWidgetComponent,
    CommentsWidgetComponent,
    TreatmentPlanActionsWidgetComponent,
    TreatmentPlansWidgetComponent,
    CreateEmailComponent,
    CreateMessageComponent,
    CreateCommentComponent,
    AppointmentWidgetComponent,
    CreateReportComponent,
    TodaysPatientDetailsTableComponent,
    FlaggedPatientsTableComponent,
    QuickNoteComponent,
    ScriptWidgetComponent,
    TreatmentPlanWidgetComponent,
    TreatmentPlanActionItemComponent,
    ProblemItemComponent,
    MedicationItemComponent,
    CreateScriptComponent,
    MedicationItemAddCardComponent,
    ProblemItemAddCardComponent,
    TreatmentPlanItemAddCardComponent,
    AllergiesWidgetComponent,
    AllergyItemComponent,
    AllergyAddCardComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    FileUploadsModule,
    ReactiveFormsModule,
    RouterModule,
    SharedModule,
    ReportRendererModule,
    TranscribeModule,
    MatMenuModule,
    MatCardModule,
    MatInputModule,
    MatListModule,
    MatIconModule,
    MatButtonModule,
    MatSelectModule,
    MatDialogModule,
    MatTableModule,
    MatAutocompleteModule,
    MatTooltipModule,
  ],
  providers: [
    TextSanitizerPipe,
  ],
  exports: [
    AddressFormComponent,
    ContactFormComponent,
    SecondaryContactFormComponent,
    DoctorDisplayCardComponent,
    DoctorAddCardComponent,
    EmailFormComponent,
    PatientFormComponent,
    MedicationWidgetComponent,
    ProblemWidgetComponent,
    AppointmentsWidgetComponent,
    ScriptsWidgetComponent,
    CommentsWidgetComponent,
    TreatmentPlanActionsWidgetComponent,
    TreatmentPlansWidgetComponent,
    CreateEmailComponent,
    CreateMessageComponent,
    CreateCommentComponent,
    CreateReportComponent,
    FlaggedPatientsTableComponent,
    TodaysPatientDetailsTableComponent,
    QuickNoteComponent,
    TreatmentPlanActionItemComponent,
    ProblemItemComponent,
    MedicationItemComponent,
    CreateScriptComponent,
    MedicationItemAddCardComponent,
    ProblemItemAddCardComponent,
    TreatmentPlanItemAddCardComponent,
    AllergiesWidgetComponent,
    AllergyItemComponent,
    AllergyAddCardComponent,
  ]
})
export class PatientsSharedComponentsModule {
}
