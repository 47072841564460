<mat-card-content>
  <mat-card-title class="d-flex justify-content-between align-items-center mat-bold" [class.primary]="usePrimaryText">
    {{item.allergy}}
    <button class="mat-card-button-top-right-corner" mat-icon-button
            *appPermissionRequired="'Clinical.AllergyListing.Delete'"
            appConfirmationDialog [confirmMessage]="confirmationMessage" (confirm)="removeAllergyFromPatient(item.id)"><mat-icon>delete</mat-icon></button>
  </mat-card-title>
  <span *ngIf="item.comment && item.comment.length > 0">{{item.comment | textSanitize}}</span>
  <mat-card-subtitle class="d-flex justify-content-between">
    <span class="">{{item.lastModifiedDate | date:'yyyy-MM-dd HH:mm'}}</span>
    <span class="">{{item.lastModifiedUser.firstName}} {{item.lastModifiedUser.surname}}</span>
  </mat-card-subtitle>
</mat-card-content>
<mat-divider></mat-divider>
